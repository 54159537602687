import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {AuthService} from '../services/auth.service';
import {RouteEnum} from '../enums/route.enum';
import {DialogInjectorService} from '../services/dialog-injector.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(
    private router: Router,
    private authService: AuthService,
    private dialogInjectorService: DialogInjectorService
  ) {}

  canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (!this.authService.isAuthenticated) {
      if (state.url === RouteEnum.Home || state.url === `/${RouteEnum.Dashboard}`) {
        this.router.navigate([RouteEnum.Auth]);
      } else if (state.url === RouteEnum.Auth) {
        return of(false);
      } else {
        return this.dialogInjectorService.openAuthDialog().pipe(map(res => !!res));
      }
      return of(null);
    } else {
      try {
        return this.authService.fetchProfile().pipe(map(profile => !!profile));
      } catch (e) {
        // error fetch profile, return false
        console.error(e);
        return of(false);
      }
    }
  }
}
